import { defineStore } from 'pinia'

export const usePromotionStore = defineStore('promotionStore', {
  state: () => ({
    promotion: [],
    isChange: false,
  }),
  getters: {
    orderBy() {
      return this.promotion.filter(value => value['id'] > 0)
    },
    totalCount(state) {
      return state.promotion.length
    },
    gotChange(state) {
      return state.isChange
    },
  },
  actions: {
    setPromotionData(newData) {
      this.promotion = newData;
    },
    getPromotionById(id) {
      let result = this.promotion.find(t => t.id === id)
      return result
    },
    addPromotion(promotion) {
      this.promotion.push(promotion);
    },
    editPromotion(id, newData) {
      let foundIndex = this.promotion.findIndex(x => x.id == id);
      this.promotion[foundIndex] = newData;
    },
    deletePromotion(id) {
      this.promotion = this.promotion.filter(t => {
        return t.id !== id
      })
    },
    toggleChangeData (value) {
      this.isChange = value;
    },
    toggleMarked(id) {
      const promotion = this.promotion.find(t => t.id === id)
      promotion.marked = !promotion.marked
    },
  }
})

import { defineStore } from 'pinia'

export const useUserStore = defineStore('userStore', {
  state: () => ({
    user: "",
    userList: []
  }),
  getters: {
   
  },
  actions: {
    setUserData(newData) {
      this.user = newData;
    },
    addUser(user) {
      this.userList.push(user);
    },
    editUser(id, newData) {
      let foundIndex = this.userList.findIndex(x => x.id == id);
      this.userList[foundIndex] = newData;
    },
    deleteUser(id) {
      this.userList = this.userList.filter(t => {
        return t.id !== id
      })
    }
  }
})

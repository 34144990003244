<template>
  <v-row align="center" class="caption">
    <span class="ml-1">&copy;{{ new Date().getFullYear() }}</span>
  </v-row>
</template>

<script>
export default {

}
</script>

<style scoped>
.logoarigato {
  width: 23px;
  height: 23px;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import i18n from './i18n'
import VCalendar from 'v-calendar'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import { createPinia, PiniaVuePlugin } from 'pinia'

Vue.config.productionTip = false
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
  firstDayOfWeek: 2,
})

Vue.use(CKEditor);
Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.filter('truncate', function (value, length) {
  if (!value) return ''
  value = value.toString()
  if(value.length > length){
    return value.substring(0, length) + "..."
  }else{
    return value
  }
})

new Vue({
  router,
  vuetify,
  i18n,
  pinia,
  render: h => h(App)
}).$mount('#app')

import { defineStore } from 'pinia'

export const useWebsiteStore = defineStore('websiteStore', {
  state: () => ({
    website: {
      title: "Air Viva Admin",
      published: true,
      webUrl: {
        prefix: "https://air-viva.com/",
        name: "airviva"
      },
      favicon: "bg12.jpg",
      socialImage: "airviva/bg.jpg",
    }
  }),
  getters: {
  },
  actions: {
    setWebsiteData(newData) {
      this.website = newData;
    },
  }
})

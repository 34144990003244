export default {
  "en": {
    currency:{
      style: "currency", 
      currency: 'USD',
      currencyDisplay: "code"
    },
  },
  "es": {
    currency:{
      style: "currency",
      currency: 'VES',
      currencyDisplay: "code"
    },
  }
}
  
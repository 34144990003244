import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'
import en from 'vuetify/es5/locale/en'
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en, es },
    current: 'en',
  },
  theme: {
    themes: {
      light: {
        primary: colors.purple.darken1,
        primaryColorLight: colors.purple.lighten3,
        primaryColorLighter: colors.purple.lighten5,
        primaryColorDark: colors.purple.darken3,
        primaryColorDarker: colors.purple.darken4,
        secondary: colors.purple,
        accent: colors.grey.lighten3,
        error: colors.red.accent3,
        info: colors.lightBlue,
        success: colors.green,
        warning: colors.amber,
        drawer: colors.purple.darken1,
        drawerbackground: colors.shades.white,
        drawernumber: colors.grey.lighten2,
        selected: colors.grey.lighten4,
        mobileCard: colors.shades.white
      },
      dark: {
        primary: colors.purple.lighten2,
        primaryColorLight: colors.purple.lighten3,
        primaryColorLighter: colors.purple.lighten5,
        primaryColorDark: colors.purple.darken3,
        primaryColorDarker: colors.purple.darken4,
        secondary: colors.purple,
        accent: colors.grey.darken3,
        error: colors.purple.accent3,
        info: colors.lightBlue,
        success: colors.green,
        warning: colors.amber,
        drawer: colors.purple.lighten3,
        drawerbackground: colors.grey.darken4,
        drawernumber: colors.grey.darken3,
        selected: '#323232',
        mobileCard: '#272727'
      },
    },
  },
});


<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="8">
          <v-card class="mx-auto" min-height="180">
            <v-toolbar flat>
              <v-toolbar-title>
                <h1 class="headline">
                  <v-icon color="primary" class="mb-1 mr-2">mdi-view-dashboard</v-icon>
                  {{ $t('DASHBOARD') }} 
                </h1>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon color="grey" class="mb-1 mr-2">mdi-dots-vertical</v-icon>
            </v-toolbar>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="4">
                  <span>Dashboard Area</span>
                  <p><a href="#" class="text-decoration-none">sub content</a></p>
                </v-col>
                <v-col cols="12" md="4">
                  <span>Dominio: no conectado</span>
                  <p><a href="#" class="text-decoration-none">no conected</a></p>
                </v-col>
                <v-col cols="12" md="4">
                  <span>Dashboard Area</span>
                  <p><a href="#" class="text-decoration-none">sub content</a></p>
                </v-col>
              </v-row>

              <v-divider></v-divider>


            </v-card-text>

          </v-card>
          <v-card
                class="mt-12 mx-auto"
              >
                <v-sheet
                  class="v-sheet--offset mx-auto"
                  color="primary"
                  elevation="12"
                  max-width="calc(100% - 32px)"
                >
                  <v-sparkline
                    :labels="labels2"
                    :value="value"
                    color="white"
                    line-width="2"
                    padding="16"
                  ></v-sparkline>
                </v-sheet>

                <v-card-text class="pt-0">
                  <div class="text-h6 font-weight-light mb-2">
                    Visits Registrations
                  </div>
                  <div class="subheading font-weight-light grey--text">
                    Last stats performance
                  </div>
                  <v-divider class="my-2"></v-divider>
                  <v-icon
                    class="mr-2"
                    small
                  >
                    mdi-clock
                  </v-icon>
                  <span class="text-caption grey--text font-weight-light">last registration 26 minutes ago</span>
                </v-card-text>
              </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card class="mx-auto" >
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="headline">
                  Analíticas
                </v-list-item-title>
                <v-list-item-subtitle>Mon, 12:30 PM, web site trafic</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-card-text>
              <v-row align="center">
                <v-col
                  class="display-3"
                  cols="12">45 <span class="title">new Visitors</span>
                </v-col>
              </v-row>
            </v-card-text>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-email</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle>23 Messages</v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-cloud-download</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle>48 Download</v-list-item-subtitle>
            </v-list-item>

            <v-slider
              v-model="time"
              :max="6"
              :tick-labels="labels"
              class="mx-4"
              ticks
            ></v-slider>

            <v-list class="transparent">
              <v-list-item
                v-for="item in forecast"
                :key="item.day"
              >
                <v-list-item-title>{{ item.day }}</v-list-item-title>

                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-subtitle class="text-right">
                  {{ item.temp }}
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-card-actions class="pa-0">
              <v-btn large block tile color="primary" class="text-capitalize">
                Full Report
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="8">
          <v-card  min-height="82">
            <v-toolbar flat>
              <v-toolbar-title class="headline">
                  <v-icon color="primary" class="mb-1 mr-2">mdi-tools</v-icon>
                  Uilities 
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    
    <!--
    <div class="text-center">
      <p>{{ $n(200, "currency") }}</p>
      <i18n-n :value="4500.45" format="currency">
        <template #currency="slotProps">
          <span class="green--text title">{{ slotProps.currency }}</span>
        </template>
        <template #integer="slotProps">
          <span class="red--text font-weight-black">{{
            slotProps.integer
          }}</span>
        </template>
        <template #decimal="slotProps">
          <span class="text--disabled headline">{{ slotProps.decimal }}</span>
        </template>
        <template #fraction="slotProps">
          <span class="primary--text title">{{ slotProps.fraction }}</span>
        </template>
        <template #group="slotProps">
          <span class="blue--text title">{{ slotProps.group }}</span>
        </template>
      </i18n-n>
    </div>
    -->
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      labels2: [
        '12am',
        '3am',
        '6am',
        '9am',
        '12pm',
        '3pm',
        '6pm',
        '9pm',
      ],
      value: [
        200,
        675,
        410,
        390,
        310,
        460,
        250,
        240,
      ],
      labels: ['SU', 'MO', 'TU', 'WED', 'TH', 'FR', 'SA'],
      time: 0,
      forecast: [
        { day: 'Tuesday', icon: 'mdi-finance', temp: '12 visits' },
        { day: 'Wednesday', icon: 'mdi-finance', temp: '2 visits' },
        { day: 'Thursday', icon: 'mdi-finance', temp: '13 visits' },
      ],
      attrs: [
        {
          key: 'today',
          highlight: 'blue',
          dates: new Date(),
          popover: {
            label: 'Clases de Pintura',
            visibility: 'hover',
            hideIndicator: true,
          }
        },
      ],
    }
  },
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>

export default {
  "en": {
    short:{
      year: "numeric",
      month: "short",
      day: "2-digit",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "2-digit",
      weekday: "long",
      hour: "numeric",
      minute: "numeric"
    }
  },
  "es": {
    short:{
      year: "numeric",
      month: "short",
      day: "2-digit",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "2-digit",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
      hour12: true
    }
  }
}

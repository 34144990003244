import { defineStore } from 'pinia'

export const usePageStore = defineStore('pageStore', {
  state: () => ({
    page: [],
    isChange: false,
  }),
  getters: {
    orderBy() {
      return this.page.filter(value => value['id'] > 0)
    },
    totalCount(state) {
      return state.page.length
    },
    gotChange(state) {
      return state.isChange
    },
  },
  actions: {
    setPageData(newData) {
      this.page = newData;
    },
    getPageById(id) {
      let result = this.page.find(t => t.id === id)
      return result
    },
    addPage(page) {
      this.page.push(page);
    },
    editPage(id, newData) {
      let foundIndex = this.page.findIndex(x => x.id == id);
      this.page[foundIndex] = newData;
    },
    deletePage(id) {
      this.page = this.page.filter(t => {
        return t.id !== id
      })
    },
    toggleChangeData (value) {
      this.isChange = value;
    },
    toggleMarked(id) {
      const page = this.page.find(t => t.id === id)
      page.marked = !page.marked
    },
  }
})

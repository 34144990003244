import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import store from '@/store.js'

Vue.use(VueRouter);
const mode = "history"

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ "@/views/Login"),
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/pages",
    name: "Pages",
    component: () => import(/* webpackChunkName: "pages" */ "@/views/Pages.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/blog",
    name: "Blog",
    component: () => import(/* webpackChunkName: "blog" */ "@/views/Blog.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/tags",
    name: "Tags",
    component: () => import(/* webpackChunkName: "promotags" */ "@/views/Tags.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import(/* webpackChunkName: "settings" */ "@/views/Settings.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/users",
    name: "Users",
    component: () => import(/* webpackChunkName: "users" */ "@/views/Users.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/myaccount",
    name: "Myaccount",
    component: () => import(/* webpackChunkName: "myaccount" */ "@/views/Account.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/domains",
    name: "Domains",
    component: () => import(/* webpackChunkName: "domains" */ "@/views/Domains.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/404",
    alias: '*',
    name: "NotFound",
    component: () => import(/* webpackChunkName: "notfound" */ "@/views/NotFound"),
    meta: { requiresAuth: true }
  }
];

const router = new VueRouter({
  routes,
  mode,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
  
    if (!store.user) {
      next({ name: 'login', query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router;

import { defineStore } from 'pinia'
import { useUserStore } from './userStore'
import { useWebsiteStore } from './websiteStore'
import { usePageStore } from './pageStore'
import { usePromotionStore } from './promotionStore'
let PouchDB = require('pouchdb').default;
// let db = new PouchDB('http://192.168.200.65:5984/airviva');
let db = new PouchDB('https://airvivadb.arigato.ai/airviva');

export const useBlogStore = defineStore('blogStore', {
  state: () => ({
    blog: [],
    isLoading: false,
    isChange: false
  }),
  getters: {
    orderBy() {
      return this.blog.filter(value => value['id'] > 0)
    },
    totalCount(state) {
      return state.blog.length
    },
    gotChange(state) {
      return state.isChange
    },
  },
  actions: {
    async getBlogsData() {
      const promo = usePromotionStore()
      const website = useWebsiteStore()
      const page = usePageStore()
      const user = useUserStore()
      this.isLoading = true
      await db.allDocs(
        {include_docs: true}
      ).then((response) => { 
        this.isLoading = false
        if (response.rows.length > 0) {
          const result = response.rows[response.rows.length - 1]['doc']['data'];
          user.setUserData(result.user);
          website.setWebsiteData(result.website);
          this.blog = result.blog;
          page.setPageData(result.page);
          promo.setPromotionData(result.promotion);
        }
      }).catch(function(error){
        this.isLoading = false
        console.error(error)
      })
    },
    getBlogById(id) {
      let result = this.blog.find(t => t.id === id)
      return result
    },
    addBlog(blog) {
      this.blog.push(blog);
    },
    editBlog(id, newData) {
      console.log(`id: ${id}`)
      let foundIndex = this.blog.findIndex(x => x.id == id);
      this.blog[foundIndex] = newData;
    },
    deleteBlog(id) {
      this.blog = this.blog.filter(t => {
        return t.id !== id
      })
    },
    toggleChangeData (value) {
      this.isChange = value;
    },
    toggleMarked(id) {
      const blog = this.blog.find(t => t.id === id)
      blog.marked = !blog.marked
    },
  }
})

<template>
  <v-app id="inspire">

    <!-- Main Drawer -->
    <v-navigation-drawer v-if="filterRouter()" v-model="drawer" fixed app color="drawerbackground"> 
      <v-list>
        <v-list-item link>
          <v-list-item-avatar class="mr-3 my-0 primary 
justify-center" size="46">
            <v-avatar color="primary lighten-1" size="45">
              <v-icon color="white">mdi-account</v-icon>
              <!-- <v-img src="@/assets/person.jpg"></v-img> -->
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="subtitle-1">
              Jhon Travel
            </v-list-item-title>
            <v-list-item-subtitle class="caption">jhontravel@arigato.ai</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-menu-down</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group v-model="selectedItem" color="drawer">

          <v-list-item to="/">
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $tc('DASHBOARD', 2) }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-show="false">
              <v-avatar color="drawernumber" size="20">
                <span class="grey--drawer caption">0</span>
              </v-avatar>
            </v-list-item-action>
          </v-list-item>

          <v-list-item :to="{ name: 'Pages' }">
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-page-layout-body</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $tc('PAGE', 2) }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-avatar color="drawernumber" size="20">
                <span class="grey--drawer caption">{{ pageStore.totalCount }}</span>
              </v-avatar>
            </v-list-item-action>
          </v-list-item>

          <v-list-item :to="{ name: 'Blog' }">
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-newspaper-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $tc('BLOG', 2) }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-avatar color="drawernumber" size="20">
                <span class="grey--drawer caption">{{ blogStore.totalCount }}</span>
              </v-avatar>
            </v-list-item-action>
          </v-list-item>

          <v-list-item :to="{ name: 'Tags' }">
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-tag</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $tc('TAGSCONTENT', 2) }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-avatar color="drawernumber" size="20">
                <span class="grey--drawer caption">{{ promotionStore.totalCount }}</span>
              </v-avatar>
            </v-list-item-action>
          </v-list-item>

          <v-list-item :to="{ name: 'Settings' }">
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $tc('settings.SETTINGS', 2) }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-show="false">
              <v-avatar color="drawernumber" size="20">
                <span class="grey--drawer caption">0</span>
              </v-avatar>
            </v-list-item-action>
          </v-list-item>

        </v-list-item-group>
        <v-list-item class="mt-1" @click="logOut">
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-lock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('LOGOUT') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-card tile height="24" dark color="primary" class="d-flex">
          <v-row class="pl-5">
            <airviva-logo class="white--text pr-2" size="60px" />
            <arigato-sing />
          </v-row>
        </v-card>
      </template>
    </v-navigation-drawer>
    
    <!-- App Main Bar -->
    <v-app-bar v-if="filterRouter()" dark app dense color="primary">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link :to="{name: 'Home'}"> 
          <airviva-logo class="white--text" size="90px" />
        </router-link>
        <span class="text-title">web admin</span> | <small>v0.7-a</small>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-show="isDarkTheme" icon @click="toggleThemeValue">
        <v-icon>mdi-white-balance-sunny</v-icon>
      </v-btn>
      <v-btn v-show="!isDarkTheme" icon @click="toggleThemeValue">
        <v-icon>mdi-weather-night</v-icon>
      </v-btn>
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }"> 
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-web</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="setLocale(lang.locale)" active-class="primary" v-for="(lang, i) in langs" :key="i">
            <v-list-item-title>
            {{ $t(lang.translation) }}
            </v-list-item-title>
            <v-list-item-action v-show="$i18n.locale == lang.locale">
            <v-icon color="primary" size="16">mdi-record-circle</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu
      v-model="menu"
      :close-on-content-click="true"
      :nudge-width="200"
      max-width="320"
      offset-x
      offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar v-bind="attrs" v-on="on" color="white" size="36">
            <v-icon color="primary">mdi-account</v-icon>
            <!-- <v-img src="@/assets/person.jpg" alt="Jhon Travel"></v-img> -->
          </v-avatar>
        </template>

        <v-card class="px-2">
          <v-list>
            <v-list-item>
              <v-list-item-avatar color="primary lighten-1">
                <v-icon color="white">mdi-account</v-icon>
                <!-- <v-img src="@/assets/person.jpg" alt="Jhon Travel"></v-img> -->
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Jhon Travel</v-list-item-title>
                <v-list-item-subtitle>jhontravel@arigato.ai</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list dense>
            <v-subheader>{{ $t('settings.SETTINGS') }}</v-subheader>
            <v-list-item-group color="primary" v-model="selectedItem">
              <v-list-item v-for="(item, i) in options" :key="i" :to="item.path">
                <v-list-item-icon class="mr-4">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-divider></v-divider>

          <v-card-actions>

            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primaryColorLight" class="text-capitalize" text v-bind="attrs" v-on="on">
                  <v-icon class="mr-1">mdi-web</v-icon>
                  {{ $t('LANGUAGE') }}
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(lang, i) in langs" :key="i" @click="setLocale(lang.locale)">
                  <v-list-item-title >
                  {{ $t(lang.translation) }}
                </v-list-item-title>
                <v-list-item-action v-show="$i18n.locale == lang.locale">
                  <v-icon color="primaryColorLight" size="16">mdi-record-circle</v-icon>
                </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-spacer></v-spacer>

            <v-btn
              color="primaryColorLight"
              class="text-capitalize"
              text
              @click="setMenuAndOut">
              <v-icon class="mr-1">mdi-lock</v-icon>
              {{ $t('LOGOUT') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>

    <!-- Contenido Main -->
    <v-main>
    <transition name="slide-y-transition" mode="out-in">
      <router-view 
        :key = "$router.path" 
        :windowWidth = "windowWidth"
        :saveDataAll = "saveDataAll" />
    </transition>
    </v-main>

    <v-footer dark color="primary" class="px-6 py-3" fixed>
      <v-row justify="space-between" class="text-center caption" cols="12">
        <airviva-logo class="white--text pr-2" size="60px" />
        <arigato-sing />
        <span class="d-none d-sm-block">{{ $d(new Date(), "long") }}</span>
        <span class="d-sm-none">{{ $d(new Date(), "short") }}</span>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import ArigatoSing from '@/components/ArigatoSing.vue';
import AirvivaLogo from '@/components/AirvivaLogo.vue';
import store from '@/store.js'
import { includes} from 'lodash'
import { useBlogStore } from '@/stores/blogStore'
import { usePageStore } from '@/stores/pageStore'
import { usePromotionStore } from '@/stores/promotionStore'
import { useUserStore } from '@/stores/userStore'
import { useWebsiteStore } from '@/stores/websiteStore'

let PouchDB = require('pouchdb').default;
// let db = new PouchDB('http://192.168.200.65:5984/airviva');
let db = new PouchDB('https://airvivadb.arigato.ai/airviva');

export default {
  name: "App",
  components: {
    ArigatoSing,
    AirvivaLogo,
  },
  data: () => ({
    mainData: [],
    drawer: null,
    selectedItem: 0,
    selectedTheme: 'light',
    fav: true,
    menu: false,
    message: false,
    hints: true,
    promotionStore: usePromotionStore(),
    blogStore: useBlogStore(),
    pageStore: usePageStore(),
    userStore: useUserStore(),
    websiteStore: useWebsiteStore(),
    options: [
      { 
        text: 'Opciones de Cuenta', 
        icon: 'mdi-account-cog', 
        path: { name: "Myaccount" } 
      },
      { 
        text: 'Dominios', 
        icon: 'mdi-domain', 
        path: { name: "Domains" } 
      },
      // { 
      //   text: 'Correos', 
      //   icon: 'mdi-email', 
      //   path: '/emailconfig' 
      // },
      // { 
      //   text: 'Historial de Facturación', 
      //   icon: 'mdi-chart-bar', 
      //   path: '/history' 
      // },
      // { 
      //   text: 'Subscripciones', 
      //   icon: 'mdi-clipboard-account',
      //    path: '/subscriptions' 
      //   },
      // { 
      //   text: 'Métodos de Pago', 
      //   icon: 'mdi-credit-card-outline', 
      //   path: '/payments' 
      // },
    ],
    items: [
      { 
        total: null, 
        text: "DASHBOARD", 
        icon: "mdi-view-dashboard", 
        path: '/' 
      },
      { 
        total: 3, 
        text: "PAGE", 
        icon: "mdi-page-layout-body", 
        path: { name: 'Pages' } 
      },
      { 
        total: 3, 
        text: "BLOG", 
        icon: "mdi-newspaper-variant", 
        path: { name: 'Blog' } 
      },
      { 
        total: 2, 
        text: "TAGSCONTENT", 
        icon: "mdi-tag", 
        path: { name: 'Tags' } 
      },
      // { 
      //   total: 2, 
      //   text: "FACILITY", 
      //   icon: "mdi-domain", 
      //   path: { name: 'Facility' } 
      // },
      // { 
      //   total: 20, 
      //   text: "STUDENT", 
      //   icon: "mdi-account-multiple", 
      //   path: { name: "Students" }  
      // },
      // { total: 10, text: "SCHOOL", icon: "mdi-school", path: '/school' },
      // { total: 201, text: "LESSON", icon: "mdi-playlist-play", path: '/lessons' },
      // { 
      //   total: 19, 
      //   text: "CALENDAR", 
      //   icon: "mdi-calendar", 
      //   path: { name: 'Calendar' }
      // },
      // { 
      //   total: null, 
      //   text: "PLUGIN", 
      //   icon: "mdi-cube", 
      //   path: { name: 'Plugins' }
      // },
      { 
        total: null, 
        text: "settings.SETTINGS", 
        icon: "mdi-cog", 
        path: { name: 'Settings' } 
      },
      // { 
      //   total: null, 
      //   text: "user.USER", 
      //   icon: "mdi-account-key", 
      //   path: '/users' 
      // },
    ],
    langs: [
      {
        locale: "es",
        translation: "SPANISH"
      },
      {
        locale: "en",
        translation: "ENGLISH"
      }
    ],
    routesFalse: ['login'],
    windowWidth: window.innerWidth,
  }),
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  created() {
    this.checkLocale();
    this.checkTheme();
    this.loadData();
  },
  computed:{
    isDarkTheme(){
      return this.$vuetify.theme.dark;
    },
  },
  methods: {
    saveDataAll () {
      // db.info().then(info => console.log(info));
      db.put({ 
        _id: `airviva-db-${this.generateCode()}`,
        data: {
          user: useUserStore().user,
          website: useWebsiteStore().website,
          page: usePageStore().page,
          blog: useBlogStore().blog,
          promotion: usePromotionStore().promotion
        }
      }).then((response) => {
        this.loadData();
        console.log('Document saved successfully:', response);
      }).catch(function (err) {
        console.error('Error saving document:', err);
      });
    },
    loadData () {
      this.blogStore.getBlogsData();
    },
    generateCode() {
      let currentDate = new Date(); 
      let day = `${currentDate.getDate()}`;
      day = this.vefifyResult(day);
      let month = `${currentDate.getMonth()+1}`;
      month = this.vefifyResult(month);
      let year = `${currentDate.getFullYear()}`;
      let hour = `${currentDate.getHours()}`;
      hour = this.vefifyResult(hour);
      let minutes = `${currentDate.getMinutes()}`;
      minutes = this.vefifyResult(minutes);
      let miliseconds = `${currentDate.getMilliseconds()}`;
      miliseconds = this.vefifyResult(miliseconds);
      let time = `${hour}:${minutes}:${miliseconds}`;
      let temp = `${day}/${month}/${year}-${time}`;
      return temp;
    },
    vefifyResult(value) {
      if (value < 10) return `0${value}`; else return value;
    },
    checkLocale() {
      const locale = localStorage.getItem("locale");
      if (locale) {
        this.$i18n.locale = locale;
      } else if (navigator.language) {
        this.$i18n.locale = navigator.language.substring(0, 2);
        localStorage.setItem("locale", this.$i18n.locale);
      }
      this.setVuetifyLocale();
    },
    checkTheme() {
      const themeLoaded = localStorage.getItem('theme');
      if (themeLoaded) {
        this.selectedTheme = themeLoaded;
        if (themeLoaded === 'dark'){
          this.setThemeValue(true);
        }
      } else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.setThemeValue(true);
        this.selectedTheme = 'dark';
      } else {
        this.setThemeValue(false);
        this.selectedTheme = 'light';
      }
      if (!('theme' in localStorage)) {
        this.selectedTheme = 'auto';
      }
    },
    setLocale(value) {
      this.$i18n.locale = value;
      localStorage.setItem("locale", value);
      this.setVuetifyLocale();
    },
    toggleThemeValue(){
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      if (this.$vuetify.theme.dark) {
        localStorage.setItem("theme", 'dark');
        this.selectedTheme = 'dark';
      } else {
        localStorage.setItem("theme", 'light');
        this.selectedTheme = 'light';
      }
    },
    setThemeValue(value){
      this.$vuetify.theme.dark = value
    },
    setVuetifyLocale() {
      this.$vuetify.lang.current = this.$i18n.locale;
    },
    setMenuAndOut () { 
      this.menu = false
      this.logOut()
    },
    logOut(){
      store.user = null;
      this.$router.push("/login");
      this.drawer = null
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    filterRouter () {
      return !includes(this.routesFalse, this.$route.name)
    }
  }
};
</script>

<style lang="scss">
  .v-toolbar__title {
      padding-left: 4px !important;
      min-width: 180px !important;
  }
  .v-btn {
    text-transform: none !important;
  }
  a {
    text-decoration: none;
  }
  .v-tabs-bar {
    border-radius: unset !important;
    border-bottom: rgba(134, 134, 134, 0.212) solid thin !important;
  }
  .v-application--is-ltr .v-data-footer__pagination {
    margin: 0 8px 0 8px !important;
  }
</style>
